import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyD0N1723mZ82P4VDt54t0ReLzpcympqFok",
    // authDomain: "skywatch-website.firebaseapp.com",
    authDomain: "skywatchgames.com",
    projectId: "skywatch-website",
    storageBucket: "skywatch-website.appspot.com",
    messagingSenderId: "680832203838",
    appId: "1:680832203838:web:8631dbb3088f62bf88656f",
    measurementId: "G-TTRXD18L8X"
};

export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);


// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(app);