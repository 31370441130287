import React from 'react';
import AppView from '@/views/AppView';
import ErrorBoundary from '@/modules/ErrorBoundary';

function App () {
    return (
        <ErrorBoundary name="App">
            <AppView/>
        </ErrorBoundary>
    );
}

export default App;
