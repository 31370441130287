import React from 'react';
import '@/css/global.css';
import { perf } from '@/firebase';

interface AppViewProps {
}

const AppView: React.FC<AppViewProps> = ({ ...props }) => {

    const p = perf;

    const programs = [
        'UnityLogo',
        'UnrealLogo',
        'OpenAiLogo',
    ];

    const languages = [
        'ReactLogo',
        'CsharpLogo',
        'C++Logo',
        'PythonLogo',
        'PhpLogo',
        'SqlLogo',
        ];

    const iconButtons = [
        {
            icon: 'LinkedIn.png',
            onClick: () => window.open('https://www.linkedin.com/company/QuiplyAI')
        },
        {
            icon: 'Twitter.svg',
            onClick: () => window.open('https://twitter.com/QuiplyAI')
        },
        {
            icon: 'Discord.svg',
            onClick: () => window.open('https://discord.gg/rkuYu77hBP')
        },
        {
            icon: 'Github.svg',
            onClick: () => window.open('https://github.com/QuiplyAi')
        }
    ];

    return (
        <div className={'fixed inset-0 overflow-clip bg-gradient-to-b from-indigo-950 to-sky-700 flex flex-col justify-center items-center'}>

            <div className={'absolute inset-0 z-0 flex justify-center items-center'}>
                <img src={'backgrounds/StarBG.png'} alt={'Logo'} className={'opacity-10 object-cover h-full w-full z-50 hover:scale-110 duration-1000 transition-all flex-grow'} />
            </div>

            <div className={'absolute inset-0 z-10 flex justify-center items-center'}>
                <div className={'w-5/6 md:w-[500px] h-full bg-black opacity-20 mr-2'} />
            </div>

            <div className={'absolute inset-0 overflow-y-auto overflow-x-clip flex-grow flex w-full h-full flex-col items-center z-50'}>
                <div className={`flex flex-col max-w-4xl flex-grow gap-8 p-8`}>

                    <main className={'flex flex-col gap-8 justify-center items-center flex-grow'}>
                        <img className={'object-contain z-50 hover:scale-110 duration-1000 transition-all'} src={'brand/LogoWithName.png'} alt={'Logo'}/>

                        <div className={'flex flex-col gap-4 justify-center items-center text-graphic text-md font-thin z-50 select-none px-2 md:px-6 w-5/6 md:w-[500px] text-center'}>
                            <div className={`text-graphic text-xl text-center select-none`}>
                                About
                                <div className={`h-0.5 bg-graphic-soft w-[300px]`} />
                            </div>
                            Founded by Tom Sims and Adam Kauper, alumni of the USC Interactive Media & Games Program.
                            <br/><br/>We are a cutting-edge full stack development company that thrives on bringing your digital visions to life.
                            Specializing in work-for-hire projects, we blend contemporary style with professional rigor to deliver fun and engaging interactive solutions.
                            <br/><br/>Our portfolio boasts collaborations with notable clients such as The Scenic Route and the National Army Museum, underscoring our commitment to excellence and versatility in the dynamic world of web development.
                            Trust us to be the architects of your digital future, where modern creativity meets conventional expertise.
                        </div>

                        <div className={`flex flex-col gap-4 justify-center items-center`}>
                            <div className={`text-graphic text-xl text-center select-none`}>
                                Contact
                                <div className={`h-0.5 bg-graphic-soft w-[300px]`} />
                            </div>
                            <div className={'flex flex-row gap-4 justify-center flex-wrap w-5/6 md:w-[500px]'}>
                                {iconButtons.map((button, index) => (
                                    <button key={index} onClick={button.onClick} className={'w-8 min-h-8 hover:scale-110 active:scale-90 duration-300 transition-all'}>
                                        <img src={`/external/${button.icon}`} alt={``} className={`w-full h-full object-contain`}/>
                                    </button>
                                ))}
                            </div>
                            <div className={`text-graphic flex flex-row gap-8`}>
                                <div className={`text-graphic flex flex-col text-center gap-2`}>
                                    <a href={'mailto:adam@skywatchgames.com'} className={'sticky py-2 px-8 group z-50 rounded-full border border-graphic text-xl text-graphic hover:bg-amber-500 hover:border-transparent hover:elevation-4 duration-200 transition-all select-none'}>
                                        Adam Kauper
                                    </a>
                                    adam@skywatchgames.com
                                </div>
                                <div className={`text-graphic flex flex-col text-center gap-2`}>
                                    <a href={'mailto:tom@skywatchgames.com'} className={'sticky py-2 px-8 group z-50 rounded-full border border-graphic text-xl text-graphic hover:bg-amber-500 hover:border-transparent hover:elevation-4 duration-200 transition-all select-none'}>
                                        Tom Sims
                                    </a>
                                    tom@skywatchgames.com
                                </div>
                            </div>
                        </div>
                        <div className={'flex flex-col gap-4 items-center select-none pointer-events-none'}>
                            <div className={`text-graphic text-xl text-center select-none`}>
                                Tools and Technologies
                                <div className={`h-0.5 bg-graphic-soft w-[300px]`} />
                            </div>
                            <div className={'flex flex-row gap-4 justify-center flex-wrap w-5/6 md:w-[500px]'}>
                                {programs.map((logo, index) => (
                                    <img key={index} className={'object-contain z-50 h-full min-h-[40px] max-h-[40px] select-none pointer-events-none'} src={`/external/${logo}.png`} alt={'Logo'}/>
                                ))}
                            </div>
                            <div className={'flex flex-row gap-4 justify-center flex-wrap w-5/6 md:w-[500px]'}>
                                {languages.map((logo, index) => (
                                    <img key={index} className={'object-contain z-50 h-full min-h-[40px] max-h-[40px] select-none pointer-events-none'} src={`/external/${logo}.png`} alt={'Logo'}/>
                                ))}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default AppView;